import { BlueLink } from '@hn-ui/links';
import { styled } from 'styled-components';

export const FooterOuterContainer = styled.main.attrs({ role: 'footer' })`
  margin: 0 auto;
  max-width: 1024px;
`;

export const FooterContainer = styled.footer`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: max-content;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 24px;
  height: 100%;
`;

export const ActiveLink = styled(BlueLink)`
  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Link = styled(ActiveLink)`
  && {
    color: ${({ theme }) => theme.colors.black60};
  }
`;

export const PolicyText = styled.p`
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding: 0;
  color: ${({ theme }) => theme.colors.black60};

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const RightsText = styled.p`
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin: 16px 0 8px;
  padding: 0;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const PolicyLink = styled(ActiveLink)`
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 12px;
    line-height: 16px;
  }
`;
